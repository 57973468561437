import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";
import { authSlice } from "./Slice/authSlice";
import { userSlice } from "./Slice/userSlice";

const rootReducer = combineReducers({
  user: authSlice.reducer,
  _user: userSlice.reducer,
  // Add more slices here
});

const persistConfig = {
  key: "root",
  storage: storageSession, // This assumes you have configured storage correctly
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const Store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(Store);

export default Store;
export { persistor };
