import './App.css';
import Login from './Components/Login/Login';
import Register from './Components/Register/Register';
import Forget_pass from './Components/Forget_Password/Forget_pass';
import Home from './Pages/Home/Home';
import Otp from './Components/Otp/Otp';
import Table from './Components/DataTable/Table';
import Admin from './Pages/Admin/Admin';
import NotFound from './Pages/404/NotFound';
import PrivateRoute from './Components/PrivateRoute/PrivateRoute';
import { Route, Routes } from 'react-router-dom';
import Update_pass from './Components/Forget_Password/Update_pass';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setError } from './Toolkit/Slice/authSlice';

function App() {

  return (

    <div className="">
      <Routes>

        {/* These are all the private routes user cannot access then without logging in */}
        <Route path='/' element={<PrivateRoute />}>
          <Route path="/admin" element={<Admin />} />
          <Route path="/table" element={<Table />} />
          <Route exact path="/" element={<Home />} />
        </Route>

        {/* These are all the public routes user can access without logging in */}
        <Route path="/otp-verify" element={<Otp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forget-password" element={<Forget_pass />} />
        <Route path="/updatepassword" element={<Update_pass />} />
        <Route component={NotFound} />
      </Routes>

    </div>
  );
}

export default App;
