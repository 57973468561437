import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { verifyOtp } from "../../Toolkit/Slice/authSlice";
import { useNavigate } from "react-router-dom";
import { setError } from "../../Toolkit/Slice/userSlice";
import { signUpUser } from "../../Toolkit/Slice/authSlice";
import LoadingButton from "@mui/lab/LoadingButton";

const Otp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const email = useSelector((state) => state.user.email);
  const name = useSelector((state) => state.user.name);
  const password = useSelector((state) => state.user.password);
  const otpVerified = useSelector((state) => state.user.otpVerified);
  const got_otp = useSelector((state) => state.user.otp);
  const error = useSelector((state) => state.user.error);
  const otpLoading = useSelector((state) => state.user.otpLoading);

  const otpInputs = useRef([]);

  // State to store user input
  const [otp, setOtp] = React.useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
  });
  const [disabled, setDisabled] = useState(false);
  const [countdown, setCountdown] = useState(60);

  const [combinedOtp, setCombinedOtp] = useState("");

  // Function to handle user input
  const handleChange = (e, index) => {
    const newOtp = { ...otp, [`otp${index + 1}`]: e.target.value };
    setOtp(newOtp);
    setCombinedOtp(Object.values(newOtp).join(""));

    if (e.target.value.length === 1 && index < 5) {
      otpInputs.current[index + 1].focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setError(null));
    dispatch(verifyOtp({ email: email, otp: combinedOtp }));
  };

  const resendOTP = () => {
    dispatch(setError(null));
    dispatch(signUpUser({ email: email, name: name, password: password }));
    setDisabled(true);

    // Start the countdown timer
    let timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // After 60 seconds, enable the button and reset the countdown
    setTimeout(() => {
      clearInterval(timer);
      setDisabled(false);
      setCountdown(60);
    }, 60000);
  };

  useEffect(() => {
    if (got_otp && otpVerified) {
      navigate("/login");
    }
  }, [otpVerified]);

  return (
    <div>
      <div className="relative flex min-h-screen flex-col justify-center overflow-hidden bg-gray-50 py-12">
        <div className="relative bg-white px-6 pt-10 pb-9 shadow-xl mx-auto w-full max-w-xl rounded-2xl">
          <div className="mx-auto flex w-full max-w-md flex-col space-y-16">
            <div className="flex flex-col items-center justify-center text-center space-y-2">
              <div className="font-semibold text-3xl">
                <p>Email Verification</p>
              </div>
              <div className="flex flex-row text-sm font-medium text-gray-400">
                <p>
                  We have sent a code to your email{" "}
                  <span className="font-semibold text-gray-700">
                    {email !== " " ? email : " "}
                  </span>
                </p>
              </div>
            </div>

            <div>
              <form>
                <div className="flex flex-col space-y-16">
                  <div className="flex flex-row items-center justify-between mx-auto w-full max-w-lg">
                    {[...Array(6)].map((_, index) => (
                      <div className="w-16 h-16 px-1" key={index}>
                        <input
                          ref={(input) => (otpInputs.current[index] = input)}
                          className="w-full h-full flex flex-col px-5 items-center justify-center text-center outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                          type="password"
                          maxLength={1}
                          value={otp[`otp${index + 1}`]}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </div>
                    ))}
                  </div>

                  <div className="flex flex-col space-y-5">
                    <div className="mt-3">
                      <LoadingButton
                        type="submit"
                        loading={otpLoading}
                        onClick={handleSubmit}
                        variant="contained"
                        className="flex w-full h-[50px] justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        <span className={` ${!otpLoading ? "text-white" : ""}`}>
                          Verify OTP
                        </span>
                      </LoadingButton>
                    </div>
                    <div className="mt-2">
                      {error && (
                        <p className="text-red-500 text-xs mt-2">{error}</p>
                      )}
                    </div>
                    <div class="flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500">
                      {disabled ? (
                        <span>Resend OTP in {countdown} seconds</span>
                      ) : (
                        <>
                          <p>Didn't recieve code?</p>{" "}
                          <button
                            onClick={resendOTP}
                            disabled={disabled}
                            class="flex flex-row items-center text-blue-600"
                          >
                            Resend
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Otp;
