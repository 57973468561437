import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  Users: [],
  status: "idle",
  error: null,
  loginLoading: false,
  registerLoading: false,
  access_token: null,
  otp: null,
  email: "",
};

// To Sign-Up User
export const getAllUsers = createAsyncThunk(
  "User/GetAllUsers",
  async (jwtToken) => {
    try {
      // Make a GET request to the endpoint with the JWT token in the header
      const response = await axios.get("https://king-prawn-app-556zp.ondigitalocean.app/user/getUsers", {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      if (response.status === 200) {
        return response.data; // You can adjust this based on your API response format
      } else {
        throw new Error("Request failed with status: " + response.status);
      }
    } catch (error) {
      throw error;
    }
  }
);

// To Login User
export const deleteUser = createAsyncThunk(
  "User/DeleteUser",
  async (loginData) => {
    try {
      const response = await axios.post(
        "https://king-prawn-app-556zp.ondigitalocean.app/user/login",
        { email: loginData.email, password: loginData.password },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Request failed with status: " + response.status);
      }
    } catch (error) {
      console.log("Bhai idhr aya hua hn main");
      throw error;
    }
  }
);



export const userSlice = createSlice({
  name: "_user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.authUser = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setOtp: (state, action) => {
      state.otp = action.payload;
    },
  },
  setLoginLoading: (state, action) => {
    state.loginLoading = action.payload;
  },
  setRegisterLoading: (state, action) => {
    state.registerLoading = action.payload;
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.pending, (state) => {
        state.status = "loading";
        state.loginLoading = true;
        state.error = null;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.Users= action.payload;
        state.loginLoading = false;
        state.error = null;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.status = "failed";
        state.loginLoading = false;
        state.error = action.error.message;
      })
  },
});

// Action creators are generated for each case reducer function
export const {
  setUser,
  setLoginLoading,
  setRegisterLoading,
  setStatus,
  setError,
  setEmail,
  setOtp,
} = userSlice.actions;

export default userSlice.reducer;
