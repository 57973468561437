import React from "react";
import { getAllUsers } from "../../Toolkit/Slice/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";

const Table = () => {
  // Dispatch action to get all users
  const dispatch = useDispatch();

  // Golbal State Variables
  const access_token = useSelector((state) => state.user.access_token);
  const users = useSelector((state) => state._user.Users);

  // Local state to handle pagination
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 10;
  const [userToDelete, setUserToDelete] = React.useState({});
  const [userName, setUserName] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [deleteSuccess, setDeleteSuccess] = React.useState(false);


  // Modal Confirmation Code To Delete User
  const [showModal, setShowModal] = useState(false);

  const openModal = (userId, userName) => {
    setShowModal(true);
    setUserToDelete(userId);
    setUserName(userName);
  };
  const closeModal = () => {
    setDeleteSuccess(false);
    setShowModal(false);
    setLoading(false);
  };

  const handleDeleteUser = async () => {
    setLoading(true);

    try {
      const response = await fetch(
        "https://king-prawn-app-556zp.ondigitalocean.app/user/removeUser",
        // "http://127.0.0.1:5000/user/removeUser",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
          body: JSON.stringify({
            userId: userToDelete,
          }),
        }
      );

      if (response.status === 200) {
        setLoading(false);
        setDeleteSuccess(true);
        dispatch(getAllUsers(access_token));
      } else {
        alert("Failed to delete user");
        setLoading(false);
        setShowModal(false);
      }
    } catch (error) {
      alert("Failed to delete user");
      setLoading(false);
      setShowModal(false);
    }
  };

  // Logic to calculate index of first and last user of current page
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  // Local state to handle search term and dropdown filters
  const [searchTerm, setSearchTerm] = useState("");
  const [showAdmins, setShowAdmins] = useState(false);
  const [showVerified, setShowVerified] = useState(false);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleAdminFilterChange = (event) => {
    setShowAdmins(event.target.checked);
  };

  const handleVerifiedFilterChange = (event) => {
    setShowVerified(event.target.checked);
  };

  // Filter users based on search term and dropdown filters
  const filteredUsers = users.filter((user) => {
    const isAdminMatch = showAdmins ? user.isAdmin : true;
    const isVerifiedMatch = showVerified ? user.isVerifed : true;
    const isNameMatch = user.fullName
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const isEmailMatch = user.email
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    return isAdminMatch && isVerifiedMatch && (isNameMatch || isEmailMatch);
  });

  // Fetch all users on component mount
  useEffect(() => {
    dispatch(getAllUsers(access_token));
  }, [dispatch, access_token]);

  return (
    <>
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
          {/* Modal */}
          <div className="bg-white rounded-lg text-left overflow-hidden shadow-xl sm:max-w-lg">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              {/* Modal content */}
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <svg
                    width="64px"
                    height="64px"
                    className="h-6 w-6 text-red-600"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24.00 24.00"
                    xmlns="http://www.w3.org/2000/svg"
                    strokeWidth="0.456"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">{/* Your icon paths */}</g>
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  {deleteSuccess ? (
                    <div
                      className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
                      role="alert"
                    >
                      <strong className="font-bold">Success!</strong>
                      <span className="block sm:inline">
                        {" "}
                        User deleted successfully
                      </span>
                    </div>
                  ) : (
                    <>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        {" "}
                        Delete User{" "}
                      </h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          {" "}
                          Are you sure you want to delete user -
                          <span className="font-bold"> {userName}</span>? This
                          action cannot be undone.{" "}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              {deleteSuccess ? null : (
                <button
                  onClick={handleDeleteUser}
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {loading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Delete"
                  )}
                </button>
              )}
              <button
                onClick={closeModal}
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                {deleteSuccess ? "Close" : "Cancel"}
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="antialiased font-sans bg-gray-200">
        <div className="container mx-auto px-4 sm:px-8">
          <div className="py-8">
            <div>
              <h2 className="text-2xl font-semibold leading-tight">Users</h2>
            </div>
            <div className="my-2 flex sm:flex-row flex-col">
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Search by name or email"
                className="appearance-none rounded-l border block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              />
              <div className="relative">
                <select
                  value={showAdmins}
                  onChange={handleAdminFilterChange}
                  className="appearance-none h-full rounded-r border-t sm:rounded-r-none sm:border-r-0 border-r border-b block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
                >
                  <option value={false}>All Users</option>
                  <option value={true}>Admins Only</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    viewBox="0 0 24 24"
                    className="h-4 w-4 fill-current text-gray-500"
                  ></svg>
                </div>
              </div>
              <div className="relative">
                <select
                  value={showVerified}
                  onChange={handleVerifiedFilterChange}
                  className="appearance-none h-full rounded-r border-t sm:rounded-r-none sm:border-r-0 border-r border-b block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
                >
                  <option value={false}>All Users</option>
                  <option value={true}>Verified Only</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    viewBox="0 0 24 24"
                    className="h-4 w-4 fill-current text-gray-500"
                  ></svg>
                </div>
              </div>
            </div>
            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
              <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Name
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Email
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Verified
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Admin
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredUsers
                      .slice(indexOfFirstUser, indexOfLastUser)
                      .map((user) => (
                        <tr key={user.id}>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            {user.fullName}
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            {user.email}
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            {user.isVerifed ? "Yes" : "No"}
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            {user.isAdmin ? "Yes" : "No"}
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <button
                              onClick={() => openModal(user._id, user.fullName)}
                              className="relative text-gray-600 hover:text-red-500"
                            >
                              <FiTrash2 className="h-6 w-6" />
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
                  <span className="text-xs xs:text-sm text-gray-900">
                    Showing {indexOfFirstUser + 1} to{" "}
                    {Math.min(indexOfLastUser, users.length)} of {users.length}{" "}
                    Entries
                  </span>
                  <div className="inline-flex mt-2 xs:mt-0">
                    <button
                      onClick={prevPage}
                      disabled={currentPage === 1}
                      className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l"
                    >
                      Prev
                    </button>
                    <button
                      onClick={nextPage}
                      disabled={indexOfLastUser >= users.length}
                      className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
