import React, { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setError } from "../../Toolkit/Slice/authSlice";
import { signUpUser } from "../../Toolkit/Slice/authSlice";
import { useNavigate } from "react-router-dom";
import { setEmail,setName,setPassword } from "../../Toolkit/Slice/authSlice";
import { setOtp } from "../../Toolkit/Slice/authSlice";
import Logo from "../../logoWhite.png";
import { MdOutlineError } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";

const Register = () => {
  // Variable to dispatch actions
  const dispatch = useDispatch();

  // Variable to navigate to different routes
  const navigate = useNavigate();

  // Golbal State Variables
  const otp = useSelector((state) => state.user.otp);
  const registerLoading = useSelector((state) => state.user.registerLoading);
  const error = useSelector((state) => state.user.error);

  // State to store user input
  const [user, setUser] = React.useState({
    name: "",
    email: "",
    password: "",
  });

  // Show/Hide Password
  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState("password");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setInputType(inputType === "password" ? "text" : "password");
  };

  // Strong Password Validation Logic
  const PasswordStrength = {
    Weak: "Weak",
    Medium: "Medium",
    Strong: "Strong",
  };

  function generateColors(passwordStrength) {
    switch (passwordStrength) {
      case PasswordStrength.Weak:
        return ["#FF5722"]; // Red color for weak password
      case PasswordStrength.Medium:
        return ["#FF5722"]; // Orange color for medium password
      case PasswordStrength.Strong:
        return ["#4CAF50"]; // Green color for strong password
      default:
        return ["#FF5722"]; // Default to red color
    }
  }

  function getIcon(strength) {
    switch (strength) {
      case PasswordStrength.Weak:
        return <MdOutlineError />;
      case PasswordStrength.Medium:
        return <MdOutlineError />; // Assuming medium strength also shows the error outline icon
      case PasswordStrength.Strong:
        return <FaCheckCircle />;
      default:
        return <MdOutlineError />;
    }
  }

  function testingPasswordStrength(password) {
    if (!password) return PasswordStrength.Weak;
    let points = 0;
    if (atLeastMinimumLength(password)) points += 1;
    if (atLeastOneUppercaseLetter(password)) points += 1;
    if (atLeastOneLowercaseLetter(password)) points += 1;
    if (atLeastOneNumber(password)) points += 1;
    if (atLeastOneSpecialChar(password)) points += 1;
    if (points >= 5) return PasswordStrength.Strong;
    if (points >= 3) return PasswordStrength.Medium;
    return PasswordStrength.Weak;
  }

  // Strong Password Validation Functions
  const atLeastMinimumLength = (password) => {
    const result = new RegExp(/(?=.{8,})/).test(password);
    return result;
  };

  const atLeastOneUppercaseLetter = (password) => {
    const result = new RegExp(/(?=.*?[A-Z])/).test(password);
    return result;
  };

  const atLeastOneLowercaseLetter = (password) => {
    const result = new RegExp(/(?=.*?[a-z])/).test(password);
    return result;
  };

  const atLeastOneNumber = (password) => {
    const result = new RegExp(/(?=.*?[0-9])/).test(password);
    return result;
  };

  const atLeastOneSpecialChar = (password) => {
    const result = new RegExp(/(?=.*?[#?!@$ %^&*-])/).test(password);
    return result;
  };

  const passwordStrength = testingPasswordStrength(user.password);
  const Icon = getIcon(passwordStrength);
  const colors = generateColors(passwordStrength);

  // Function to handle user input
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setError(null));
    if (passwordStrength !== PasswordStrength.Strong) {
      dispatch(setError("Password is not strong"));
      return;
    }
    // Dispatch the register action
    dispatch(signUpUser(user));
  };

  useEffect(() => {
    dispatch(setError(null));
  }, []);

  useEffect(() => {
    if (otp) {
      dispatch(setEmail(user.email));
      dispatch(setPassword(user.password));
      dispatch(setName(user.name));
      navigate("/otp-verify");
    }
  }, [otp]);

  useEffect(() => {
    dispatch(setOtp(null));
  }, []);

  return (
    <div class="flex min-h-screen flex-col justify-center px-6 py-12 lg:px-8">
      <div className="relative bg-white px-6 pt-10 pb-9 shadow-xl mx-auto w-full max-w-lg rounded-2xl">
        <div class="sm:mx-auto sm:w-full sm:max-w-sm">
          <img class="mx-auto h-[100px] w-auto" src={Logo} alt="PMR LOGO" />
          <h2 class="mt-6 text-center text-2xl leading-9 tracking-tight text-gray-900">
            Create New Account
          </h2>
        </div>

        <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form class="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                for="name"
                class="block text-start text-sm font-medium leading-6 text-gray-900"
              >
                Name
              </label>
              <div class="mt-2">
                <input
                  id="name"
                  name="name"
                  onChange={handleChange}
                  type="text"
                  autocomplete="name"
                  required
                  class="block w-full h-[50px] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <label
                for="email"
                class="block text-start text-sm font-medium leading-6 text-gray-900"
              >
                Email
              </label>
              <div class="mt-2">
                <input
                  id="email"
                  name="email"
                  onChange={handleChange}
                  type="email"
                  autocomplete="email"
                  required
                  class="block w-full rounded-md h-[50px] border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div class="flex items-center justify-between">
                <label
                  for="password"
                  class="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
              </div>
              <div className="relative mt-2">
                <input
                  id="password"
                  name="password"
                  onChange={handleChange}
                  type={inputType}
                  autocomplete="current-password"
                  required
                  className="block w-full rounded-md h-[50px] border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />

                {user.password !== "" ? (
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                    <svg
                      className={`h-6 text-gray-700 ${
                        showPassword ? "block" : "hidden"
                      }`}
                      fill="none"
                      onClick={togglePasswordVisibility}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                    >
                      <path
                        fill="currentColor"
                        d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
                      ></path>
                    </svg>
                    <svg
                      className={`h-6 text-gray-700 ${
                        showPassword ? "hidden" : "block"
                      }`}
                      fill="none"
                      onClick={togglePasswordVisibility}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                    >
                      <path
                        fill="currentColor"
                        d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"
                      ></path>
                    </svg>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {user.password !== "" && (
                <div>
                  {/* <p>Password Strength:</p> */}
                  {passwordStrength === PasswordStrength.Weak && (
                    <span style={{ color: "red" }}>Weak</span>
                  )}
                  {passwordStrength === PasswordStrength.Medium && (
                    <span style={{ color: "orange" }}>Medium</span>
                  )}
                  {passwordStrength === PasswordStrength.Strong && (
                    <span style={{ color: "green" }}>Strong</span>
                  )}
                </div>
              )}
            </div>

            {/* <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap="5px"
              margin="10px 0"
            >
              {colors.map((color, index) => (
                <Box
                  key={index}
                  flex={1}
                  height="5px"
                  borderRadius="5px"
                  bgcolor={color}
                ></Box>
              ))}
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              gap="5px"
            >
              {getIcon(passwordStrength)}
              <Typography color={colors[0]}>{passwordStrength}</Typography>
              {passwordStrength !== PasswordStrength.Strong && (
                <>
                  <Typography
                    variant="subtitle2"
                    fontSize="1rem"
                    color="text.primary"
                    margin="0 24px 0 0"
                  >
                    To make your password stronger
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontSize="14px"
                    fontWeight={500}
                    color="text.secondary"
                  >
                    Include an uppercase letter or a special symbol like (! @ #
                    $ %)
                  </Typography>
                </>
              )}
            </Box> */}

            <div className="mt-3">
              <LoadingButton
                type="submit"
                loading={registerLoading}
                variant="contained"
                className="flex w-full h-[50px] justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <span className={` ${!registerLoading ? "text-white" : ""}`}>
                  Create Account
                </span>
              </LoadingButton>
            </div>
            <div className="mt-2">
              {error && <p className="text-red-500 text-xs mt-2">{error}</p>}
            </div>
          </form>

          <p class="mt-10 text-center text-sm text-gray-500">
            Already have account?
            <Link
              to="/login"
              class="font-semibold leading-6 ms-1 text-indigo-600 hover:text-indigo-500"
            >
              Login
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
