import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Table from "../../Components/DataTable/Table";

const Admin = () => {
  return (
    <div>
      <Navbar />
      <Table />
    </div>
  );
};

export default Admin;
