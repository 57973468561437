import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ compnent: component, ...rest }) => {
  // Golbal State Variables
  const access_token = useSelector((state) => state.user.access_token);

  return access_token ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
