import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Chat from "../../Components/ChatBox/Chat";

const Home = () => {
  return (
    <div>
      <Navbar />
      <Chat />
    </div>
  );
};

export default Home;
