import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import Logo from "../../logoWhite.png";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../Toolkit/Slice/authSlice";
import { setResetPasswordLoading } from "../../Toolkit/Slice/authSlice";
import { setError } from "../../Toolkit/Slice/authSlice";
import Alert from "@mui/material/Alert";

const Forget_pass = () => {
  const dispatch = useDispatch();
  const resetPasswordLoading = useSelector(
    (state) => state.user.resetPasswordLoading
  );
  const error = useSelector((state) => state.user.error);
  const status = useSelector((state) => state.user.status);

  const [user, setUser] = useState({
    email: "",
  });

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(resetPassword(user.email));
  };

  useEffect(() => {
    dispatch(setResetPasswordLoading(false));
    dispatch(setError(null));
  }, []);

  return (
    <div className="">
      <div class="flex min-h-screen flex-col justify-center px-6 py-12 lg:px-8">
        <div className="relative bg-white px-6 pt-10 pb-9 shadow-xl mx-auto w-full max-w-lg rounded-2xl">
          {status === "succeeded" && (
            <Alert severity="success">
              Check your email for the password reset link
            </Alert>
          )}
          <div class="sm:mx-auto sm:w-full sm:max-w-sm">
            <img class="mx-auto h-[100px] w-auto" src={Logo} alt="PMR LOGO" />
            <h2 class="mt-6 text-center text-2xl leading-9 tracking-tight text-gray-900">
              Reset Your Password
            </h2>
          </div>

          <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form class="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label
                  for="email"
                  class="block text-start text-sm font-medium leading-6 text-gray-900"
                >
                  Email
                </label>
                <div class="mt-2">
                  <input
                    id="email"
                    name="email"
                    onChange={handleChange}
                    type="email"
                    autocomplete="email"
                    required
                    class="block w-full h-[50px] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="mt-3">
                <LoadingButton
                  type="submit"
                  loading={resetPasswordLoading}
                  variant="contained"
                  className="flex w-full h-[50px] justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  <span
                    className={` ${!resetPasswordLoading ? "text-white" : ""}`}
                  >
                    Reset
                  </span>
                </LoadingButton>
              </div>
              <div className="mt-2">
                {error && <p className="text-red-500 text-xs mt-2">{error}</p>}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forget_pass;
